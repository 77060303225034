@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap);
/* html, body {
  overflow: hidden;
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.home,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

:root {
  --text-color: #000000;
  --text-secondary-color: #363636;
  
  --background-color: #FFFFFF;

  --font-family: 'Montserrat', Verdana, sans-serif ;
}

html, body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  overscroll-behavior-x: contain;
  /* Justin is worried that this change is scary because it might not stop other things that should scroll from scrolling. This will affect everything in the body. */
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  border-collapse: collapse;
}

.numberInput {
  color: var(--text-secondary-color);
}

.numberInput::-webkit-outer-spin-button, .numberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numberInput::-webkit-input-placeholder {
  color: #BBBBBB;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

* {
  /* overscroll-behavior: contain;s */
  -ms-webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

*::-webkit-scrollbar {
  display: none;
}

